// should be first
import './public-path';
// core-js is only necessary for building client bundle, so it can be in devDependencies
// eslint-disable-next-line import/no-extraneous-dependencies
import 'core-js';
import 'regenerator-runtime/runtime';

import { createClientErrorApp } from './createClientErrorApp';
import { init } from './init';

void init({
	createApp: (bootstrapData) => createClientErrorApp(bootstrapData),
});
